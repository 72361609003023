@use "../../config/variables.scss" as vars;

.investorScreen-Box {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border: 1px solid rgb(232, 232, 232);
  padding: 10px;
  border-radius: 2px;
}

.investorScreen-text1 {
  font-size: 20px;
  font-family: vars.$Bold;
}

.investorScreen-text2 {
  color: gray;
  font-size: 14px;
}

.investorScreen-timeSlot {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid rgb(207, 207, 207);
  padding: 10px;
  cursor: pointer;
  gap: 10px;
  border-radius: 2px;
}

.investorScreen-scrollBox {
  height: 50vh;
  overflow: scroll;
  overflow-x: hidden;
}

.investorScreen-logo {
  width: 100px;
  height: 110px;
  object-fit: contain;
  border: 1px solid rgb(229, 229, 229);
  padding: 2px;
}

.investorScreen-heading {
  font-size: 20px;
  font-family: vars.$ROC;
  color: rgb(66, 66, 66);
}

.investorScreen-SlotsItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border: 1px solid rgb(231, 231, 231);
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff4b;
}

.investorScreen-startupItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgb(220, 220, 220);
  margin-bottom: 15px;
  padding: 10px;
  gap: 20px;
}

.investorScreen-startuplogo {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-right: 1px solid rgb(220, 220, 220);
  padding-right: 10px;
}

.investorScreen-startuptext1 {
  font-size: 20px;
  margin-bottom: 2px;
  font-family: vars.$SemiBold;
}

.investorScreen-startuptext2 {
  color: grey;
  font-size: 12px;
}

.investorScreen-SlotsItem2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.investorScreen-SlotsImg {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 100px;
}

.investorScreen-Slotstext1 {
  font-size: 16px;
  border-left: 5px solid vars.$primary;
  padding-left: 10px;
  border-radius: 2px;
  border-right: 1px solid rgb(219, 219, 219);
  padding-right: 10px;
}

.investorScreen-Slotstext2 {
  margin-bottom: 5px;
  font-size: 16px;
  font-family: vars.$SemiBold;
}

.investorScreen-Slotstext3 {
  font-size: 12px;
  color: gray;
}

.investorScreen-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.investorScreen-text5 {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
  color: grey;
}

.investorScreen-Box2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.investorScreen-formItem {
  border: 1px solid rgba(128, 128, 128, 0.5);
  margin-bottom: 15px;
  padding: 18px 20px 10px 20px;
}

.investorScreen-formRow {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-family: vars.$Medium;
  color: vars.$primary;
  margin-bottom: 10px;
}
