@use "../../config/variables.scss" as vars;
.DashboardScreen-box {
  border: 1px solid #dddddd;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #e7efff;
  cursor: pointer;
}
.DashboardScreen-box1 {
  border: 1px solid #e9e9e9;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #fff;
}

.DashboardScreen-box2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.DashboardScreen-text1 {
  font-size: 20px;
  font-family: vars.$SemiBold;
}

.DashboardScreen-text2 {
  color: rgb(119, 119, 119);
  font-family: vars.$Medium;
  font-size: 14px;
}

.DashboardScreen-text3 {
  color: grey;
  font-family: vars.$Regular;
  font-size: 12px;
}

.DashboardScreen-box3 {
  background-color: #e3efff;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: vars.$primary;
}

.DashboardScreen-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
