@use "../../config/variables.scss" as vars;

.VcDetailsScreen {
  min-height: 100vh;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 10px;
  }
}

.VcDetailsScreen-box1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid rgba(216, 216, 216, 0.477);
  padding-bottom: 10px;
}

.VcDetailsScreen-roud {
  background-color: rgba(230, 233, 255, 0.575);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid rgb(239, 239, 239);
  font-family: vars.$SemiBold;
  gap: 10px;
}

.VcDetailsScreen-roud1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid rgb(255, 255, 255);
  border-radius: 100%;
  aspect-ratio: 1;
  height: 170px;
}

.VcDetailsScreen-roud2 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 100%;
  aspect-ratio: 1;
  height: 150px;
}

.VcDetailsScreen-text1 {
  font-size: 18px;
  font-family: vars.$ROC;
  color: #000;
}

.VcDetailsScreen-text2 {
  font-size: 14px;
  font-family: vars.$Medium;
  color: grey;
}

.VcDetailsScreen-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.VcDetailsScreen-text3 {
  font-family: vars.$Regular;
  font-size: 14px;
  color: grey;
}

.VcDetailsScreen-text4 {
  font-family: vars.$SemiBold;
  font-size: 14px;
}

.VcDetailsScreen-AssociateItem {
  margin-bottom: 20px;
  border: 1px solid rgb(230, 230, 230);
  width: 100%;
  padding: 10px 20px;
}

.VcDetailsScreen-text5 {
  font-size: 25px;
  font-family: vars.$ROC;
  color: #000;
}

.VcDetailsScreen-text6 {
  font-size: 12px;
  font-family: vars.$Regular;
  color: grey;
}

.VcDetailsScreen-MeetingItem {
  border: 1px solid rgb(216, 216, 216);
  margin-bottom: 15px;
  padding: 15px;
}

.VcDetailsScreen-box2 {
  border-right: 1px solid rgb(216, 216, 216);
  padding-right: 20px;
  margin-right: 10px;
  border-left: 5px solid #3dd966;
  padding-left: 10px;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 20px;
    border-right: 0px;
  }
}

.VcDetailsScreen-text7 {
  font-size: 25px;
  font-family: vars.$SemiBold;
  color: #777777;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
}

.VcDetailsScreen-text9 {
  display: none;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
    font-size: 25px;
    font-family: vars.$SemiBold;
    color: #777777;
  }
}

.VcDetailsScreen-text8 {
  font-size: 14px;
  font-family: vars.$Medium;
  color: vars.$primary;
}

.VcDetailsScreen-modal {
  height: 70vh;
  overflow: scroll;
  overflow-x: hidden;
}

.VcDetailsScreen-MeetingItem2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.VcDetailsScreen-imageBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}
.CompanyInfo-box1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.VcDetailsScreen-evnt {
  border: 1px solid rgb(228, 228, 228);
  padding: 15px;
}

.VcDetailsScreen-evnt-image {
  height: 200px;
  object-fit: contain;
  margin-bottom: 10px;
}

.VcDetailsScreen-text10 {
  font-family: vars.$SemiBold;
  font-size: 20px;
}

.VcDetailsScreen-text11 {
  font-family: vars.$SemiBold;
  font-size: 14px;
}
