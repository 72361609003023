@use "../../config/variables.scss" as vars;
.Footer {
  background-color: vars.$secondary;
  padding: 20px 0px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 20px 10px;
  }
}

.Footer-text1 {
  font-family: vars.$ROC;
  font-size: 10px;
  text-decoration: none;
  color: #000;
}

.Footer-text2 {
  font-family: vars.$ROC;
  font-size: 10px;
  text-decoration: none;
  color: #000;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    text-align: right;
  }
}

.Footer-text3 {
  font-family: vars.$SemiBold;
  font-size: 12px;
  text-align: right;
  color: gray;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    text-align: center;
    padding-top: 20px;
  }
}
