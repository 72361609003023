.meetingScreen-tableCell {
  width: 200px;
  word-wrap: break-word;
  font-size: 12px;
}

.meetingScreen-tableCell span {
  display: block;
}
.btn-check-out {
  color: #c2c1c1;
  &:hover {
    color: #0026ab !important;
    opacity: 0.8 !important;
  }
}

// .meting-detailsDescription {
//   min-height: 75px;
//   flex: 1;
//   display: -webkit-box;
//   font-size: 13px;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
//   overflow: scroll; /* Hide scrollbar */
//   text-overflow: ellipsis;
// }

// /* For WebKit browsers */
// .meting-detailsDescription::-webkit-scrollbar {
//   display: none;
// }

// /* Optional: For other browsers */
// .meting-detailsDescription {
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;     /* Firefox */
// }
.meting-detailsDescription {
  min-height: 70px;
  flex: 1;
  display: -webkit-box;
  font-size: 13px;
  /* font-family: vars.$Regular; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: scroll; /* Scroll in the Y direction */
  text-overflow: ellipsis;
}

/* For WebKit browsers (Chrome, Safari) */
.meting-detailsDescription::-webkit-scrollbar {
  width: 1px; 
}

.meting-detailsDescription::-webkit-scrollbar-thumb {
  background-color: lightgray; 
  border-radius: 10px;
}

.meting-detailsDescription::-webkit-scrollbar-track {
  background: transparent; 
}

.meting-detailsDescription::-webkit-scrollbar-button {
  display: none; 
}

/* For Firefox */
.meting-detailsDescription {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: lightgray transparent; /* Thumb and track colors */
}

/* Firefox scrollbar styling */
.meting-detailsDescription::-moz-scrollbar-button {
  display: none; /* Removes arrows in Firefox */
}



.btn-meeting-in {
  background-color: #1890ff;
  color: #ffffff;
  border-color: #1890ff;
}

.btn-meeting-out {
  background-color: #ff4d4f;
  color: #ffffff;
  border-color: #ff4d4f;
  &:hover {
    opacity: 0.8;
    background-color: #ff4d4f !important;
    color: #ffffff;
    border-color: #ff4d4f;
  }
}
