@use "../../config/variables.scss" as vars;

.settingScreen-pageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
  gap: 20px;
}
