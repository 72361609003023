@use "../../config/variables.scss" as vars;

.EventScreen-headerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  font-size: 20px;
  font-family: vars.$SemiBold;
}
.Event_Card {
  background-color: #ffffff;
  border-radius: 2px;
  margin: 10px 0px;
  overflow: hidden;
  border: 1px solid rgb(237, 237, 237);
  padding: 20px;
}

.EventCard-Box1 {
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
}

.image-container img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

.EventScreen-Box {
  min-height: 70vh;
}

.EventCard-text1 {
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: vars.$SemiBold;
  color: vars.$primary;
  cursor: default;
}

.EventCard-text2 {
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  font-size: 14px;
  font-family: vars.$Regular;
}
