//colors
$primary: #0026ab;
$secondary: #eff1f5;

$Bold: "Poppins-Bold";
$Light: "Poppins-Light";
$SemiBold: "Poppins-SemiBold";
$Medium: "Poppins-Medium";
$Regular: "Poppins-Regular";
$ROC: "Roc_Grotesk_Wide";
