@use "../../config/variables.scss" as vars;
.Header {
  height: 82px;
  border-bottom: 1px solid rgb(226, 226, 226);
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -3px 4px 2px rgba(165, 155, 155, 0.278);
}

.Header-space {
  height: 82px;
}

.Header-logoBox {
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 0px 0px 0px 10px;
  }
}

.Header-logo {
  height: 50px;
  // width: 130px;
  object-fit: cover;
}

.breadcrumbs {
  font-size: 12px;
  font-family: vars.$Regular;
  color: #9d9d9d;
}

.breadcrumbs-active {
  color: #fff;
}


.Header-col {
  height: 100% !important;
  margin: 0px 25px !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  @media (max-width: 576px) {
    margin: 0px !important;
    justify-content: start !important;
    align-items: center !important;
  }
}
