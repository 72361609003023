@use "../../config/variables.scss" as vars;
.CompanyHeader {
  background-color: #f5f8fc;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px 0px 10px;
  color: vars.$primary;
  gap: 20px;
  border-bottom: 1px solid rgb(237, 237, 237);
}

.CompanyHeader-text1 {
  color: vars.$primary;
  font-family: vars.$ROC;
  flex: 1;
  margin-left: 20px;
}

.SideBar {
  padding: 20px;
  border-right: 1px solid rgb(237, 237, 237);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.SideBar-logo {
  height: 35px;
  padding-left: 8px;
}

.SideBar-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: rgb(230, 238, 255);
    color: vars.$primary;
  }
  &.active {
    background-color: rgb(230, 238, 255);
    color: vars.$primary;
  }
}

.CompanyRoute-box {
  padding: 10px;
  height: 92vh;
}

.CompanyHeader-popover {
  text-align: center;
}

.CompanyHeader-timer {
  font-family: vars.$ROC;
  font-size: 14px;
  border: 1px solid rgb(217, 217, 217);
  padding: 5px 20px;
}

.CompanyHeader-pageHeader {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid rgb(231, 231, 231);
  padding-bottom: 15px;
}

.CompanyHeader-txt1 {
  font-family: vars.$SemiBold;
  color: #000;
  font-size: 20px;
  margin-bottom: 2px;
}

.CompanyHeader-txt2 {
  font-size: 12px;
  color: gray;
}

.CompanyHeader-extra {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.CompanyHeader-pageHeader-Box {
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
  color: gray;
  &:hover {
    background-color: rgb(230, 238, 255);
    color: vars.$primary;
  }
}

.SideBar-eventBox {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 7px;
  border: 1px solid rgb(237, 237, 237);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    color: vars.$primary;
  }
}

.SideBar-txt {
  flex: 1;
  display: -webkit-box;
  font-size: 13px;
  font-family: vars.$Regular;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.EventMore-Box {
  width: 260px;
}

.EventMore-text1 {
  color: grey;
  font-family: vars.$Regular;
  font-size: 11px;
  margin-bottom: 6px;
  padding-top: 10px;
  border-top: 1px solid rgb(237, 237, 237);
}

.EventMore-text2 {
  font-family: vars.$Medium;
  font-size: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(237, 237, 237);
}

.EventMore-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 7px;
  border-bottom: 1px solid rgb(237, 237, 237);
  cursor: pointer;
  font-size: 13px;
  &:hover {
    background-color: rgb(230, 238, 255);
    color: vars.$primary;
  }
}

.SideBar-txt2 {
  flex: 1;
  display: -webkit-box;
  font-size: 12px;
  font-family: vars.$Regular;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.view-more {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  justify-content: center;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}
.icon {
  color: grey;
  &:hover {
    color: blue;
  }
}
