@use "../../config/variables.scss" as vars;
.AdminHeader {
  background-color: #f5f8fc;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px 0px 10px;
  color: vars.$primary;
  gap: 20px;
}

.AdminHeader-text1 {
  color: vars.$primary;
  font-family: vars.$ROC;
  flex: 1;
  padding-left: 15px;
}

.SideBar {
  padding: 20px;
  border-right: 1px solid rgb(237, 237, 237);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.SideBar-logoBox {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: vars.$SemiBold;
  font-size: 20px;
}

.SideBar-logo {
  height: 60px;
}

.SideBar-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: rgb(230, 238, 255);
    color: vars.$primary;
  }
  &.active {
    background-color: rgb(230, 238, 255);
    color: vars.$primary;
  }
}

.AdminRoute-box {
  padding: 10px;
}

.AdminHeader-popover {
  text-align: center;
}

.AdminHeader-timer {
  font-family: vars.$ROC;
  font-size: 14px;
  border: 1px solid rgb(217, 217, 217);
  padding: 5px 20px;
}

.AdminHeader-pageHeader {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid rgb(231, 231, 231);
  padding-bottom: 15px;
}

.AdminHeader-txt1 {
  font-family: vars.$SemiBold;
  color: #000;
  font-size: 20px;
  margin-bottom: 2px;
}

.AdminHeader-txt2 {
  font-size: 12px;
  color: gray;
}

.AdminHeader-extra {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.AdminHeader-pageHeader-Box {
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
  color: gray;
  &:hover {
    background-color: rgb(230, 238, 255);
    color: vars.$primary;
  }
}
