@use "../../config/variables.scss" as vars;

.access_title {
  display: flex;
  justify-content: space-between;
  border: solid 1px #f1f1f1;
  background-color: vars.$secondary;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  &h6 {
    font-size: 15px;
    font-family: vars.$SemiBold;
  }
}
.access_img{
    width: auto;
    height: 100%;
}
.muted_text{
  color: rgb(203, 201, 201);
}