@use "../../config/variables.scss" as vars;
.VcFormScreen-Box {
  min-height: 100vh;
  height: 100%;
}

.VcFormScreen-Box2 {
  background-color: vars.$primary;
  min-height: 100vh;
  height: 100%;
  // padding: 20px 0px 0px 0px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    // padding: 10px 20px 50px 20px;
    height: 100%;
    min-height: 10vh;
  }
}

.VcFormScreen-sticky {
}

.VcFormScreen-Box3 {
  height: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    min-height: 20vh;
  }
}

.VcFormScreen-Box4 {
  height: 100%;
  padding: 20px 20px 0px 0px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 0px 10px 50px 10px;
  }
}

.VcFormScreen-txt1 {
  color: #fff;
  margin-bottom: 20px;
  font-family: vars.$ROC;
  font-weight: 900;
  font-size: 2vw;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 40px;
  }
}

.VcFormScreen-txt2 {
  color: #fff;
  font-family: vars.$SemiBold;
  line-height: 30px;
  font-size: 14px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 13px;
  }
}

.VcFormScreen-img1 {
  object-fit: fill;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: 20px;
  }
}

.VcFormScreen-upload {
  border: 1px solid rgb(212, 212, 212);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: grey;
  font-size: 10px;
}

.VcFormScreen-formBox {
  border: 1px dashed rgb(184, 184, 184);
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  padding-top: 10px;
  font-family: vars.$ROC;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.VcFormScreen-FormBox2 {
  margin-top: 0px;
}

.VcFormScreen-FormBox3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  margin-bottom: 10px;
  cursor: pointer;
}

.VcFormScreen-txt3 {
  font-family: vars.$ROC;
  font-size: 15px;
}

.VcFormScreen-successBox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: vars.$Regular;
  color: grey;
  font-size: 14px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
}

.VcFormScreen-text1 {
  font-size: 30px;
  font-family: vars.$SemiBold;
  color: #000;
}

.VcFormScreen-line {
  background-color: #bbbbbb;
  height: 1px;
}

.VcFormScreen-txt4 {
  font-family: vars.$ROC;
  color: #fff;
}

.VcFormScreen-box5 {
  padding-right: 70px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-right: 0px;
  }
}

.VcFormScreen-txt6 {
  color: #fff;
  font-size: 14px;
  font-family: vars.$Regular;
  line-height: 21px;
  margin-top: 8px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 13px;
  }
}

.VcFormScreen-txt5 {
  font-size: 12px;
  margin-top: 2px;
  font-family: vars.$Regular;
  color: gray;
}

.VcFormScreen-text7 {
  font-family: vars.$Bold;
  font-weight: bolder;
}
.Loading_Box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Loading-logo {
  height: 60px;
  width: 130px;
  object-fit: contain;
}
.event-img {
  // width: 50px;
  height: 50px;
  object-fit: cover;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: 200px;
  }
}
