@use "../../config/variables.scss" as vars;

.ImagePicker-Box {
  border: 1px solid rgb(212, 212, 212);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: grey;
  font-size: 10px;
}
.imagePicker_placeholder {
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.88);
  opacity: 0.3;
}
.imagePicker_text {
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.88);
  display: flex;
  align-items: center;
  gap: 5px;
}
