@use "../../config/variables.scss" as vars;

.home_bg1 {
  height: 100vh;
  background: vars.$secondary;
  padding: 0;
  margin: 0;
  background-image: url("../../assets/images/Arrows.svg");
}

.Landing_Box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  @media screen and (max-width: 992px) {
    align-items: center;

    padding: 0px 6px;
  }
}

.Landing_Box2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media screen and (max-width: 992px) {
    align-items: start;
  }
}
.Landing_logo {
  height: 150px;
  width: 150px;
  object-fit: contain;
}
.Home_text1 {
  font-size: 70px;
  font-family: vars.$SemiBold;
  @media screen and (max-width: 992px) {
    font-size: 30px;
    text-align: center;
  }
}

.Home_text2 {
  font-size: 25px;
  font-family: vars.$SemiBold;
  margin-top: 10px;
  color: vars.$primary;
  @media screen and (max-width: 992px) {
    text-align: center;
  }
}
.icons_box {
  display: flex;
  align-items: center;
}
.Home_text3 {
  font-size: 15px;
  font-family: vars.$SemiBold;
  width: 70%;
  padding: 0px 5px;
  @media screen and (max-width: 992px) {
    text-align: center;
  }
}

.Home_text4 {
  font-family: vars.$SemiBold;
  font-size: 14px;
  color: blue;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
