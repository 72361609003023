@use "../../config/variables.scss" as vars;
.startupScreen-text1 {
  font-size: 30px;
  margin-top: 10px;
}
.sponsor-FilePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.sponsor-FilePicker2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border: 1px solid rgb(215, 215, 215);
  padding-right: 20px;
  font-family: vars.$Regular;
  color: gray;
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}
.startupScreen-text2 {
  color: rgb(80, 80, 80);
  font-size: 14px;
  font-family: vars.$Regular;
}

.startupScreen-heading {
  font-size: 20px;
  font-family: vars.$ROC;
  color: rgb(66, 66, 66);
}

.startupScreen-SlotsItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border: 1px solid rgb(231, 231, 231);
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff4b;
}

.startupScreen-Slotstext1 {
  font-size: 16px;
  border-left: 5px solid vars.$primary;
  padding-left: 10px;
  border-radius: 2px;
  border-right: 1px solid rgb(219, 219, 219);
  padding-right: 10px;
}

.startupScreen-SlotsItem2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.startupScreen-SlotsImg {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 100px;
}

.startupScreen-Slotstext2 {
  margin-bottom: 5px;
  font-size: 16px;
  font-family: vars.$SemiBold;
}

.startupScreen-Slotstext3 {
  font-size: 12px;
  color: gray;
}

.startupScreen-SlotsItem3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: space-between;
}
