@use "../../config/variables.scss" as vars;

.LoginScreen {
  min-height: 85vh;
}

.LoginScreen-box1 {
  background-color: vars.$primary;
  min-height: 200px;
}

.LoginScreen-text1 {
  font-family: vars.$Bold;
  text-align: center;
  color: #fff;
  font-size: 40px;
}

.LoginScreen-logoBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.LoginScreen-img {
  margin-top: -70px;
  width: 100px;
  height: 100px;
}
