body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.label {
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 400;
  color: rgb(58, 58, 58);
}

.table-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  cursor: pointer;
}

.devider {
  border-right: 1px solid rgb(222, 222, 222);
  width: 40%;
  min-height: 100vh;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
}

@font-face {
  font-family: "Roc_Grotesk_Wide";
  src: url("./assets/fonts/Roc_Grotesk_Wide_Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./assets/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}
